import React, { useEffect, useContext, useState, useCallback } from 'react'
import { UserContext } from '../../services/contexts/userContext'
import { Table, Typography, Input } from 'antd'
import { escapeRegExp } from 'services/helpers/helpers'
import { ErrorPage } from '../../pages/Error/ErrorPage'

const { Title, Text } = Typography
const { Search } = Input

export const GeneralTable = ({ getItems, columns, title }) => {
  const userContext = useContext(UserContext)
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [action, setAction] = useState<string>('')
  const [error, setError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [total, setTotal] = useState<number>(0)
  const [pagination, setPagination] = useState<Record<string, number>>({
    current: 1,
    pageSize: 50,
  })

  const casesView = title.toLowerCase() === 'cases'
  const poolsView = title.toLowerCase() === 'pools'

  const fetchItems = useCallback(
    (search = searchValue, pageData = pagination, act = action) => {
      setIsLoading(true)
      const args = [userContext, search, pageData]
      if (casesView) args.push(act)

      getItems(...args)
        .then((response) => {
          setFilteredItems(Object.values(response)[0] as any[])
          setTotal(Object.values(response)[1] as number)
          setIsLoading(false)
        })
        .catch((error) => {
          setError(error)
          setIsLoading(false)
        })
    },
    [userContext, searchValue, pagination, action, casesView, getItems]
  )

  useEffect(() => {
    fetchItems()
  }, [searchValue, pagination, action, fetchItems])

  const onSearch = (searchInput: string) => {
    const escapedInput = escapeRegExp(searchInput)
    setSearchValue(escapedInput)

    const newPagination = { current: 1, pageSize: 50 }
    setPagination(newPagination)

    fetchItems(escapedInput, newPagination, action)
  }

  const onChange = (newPagination, filters) => {
    setPagination(newPagination)

    if (casesView) {
      const actionValue =
        Array.isArray(filters?.action) && filters.action.length > 0 ? filters.action[0] : ''
      setAction(actionValue)
      fetchItems(searchValue, newPagination, actionValue)
    } else {
      fetchItems(searchValue, newPagination)
    }
  }

  return error ? (
    <ErrorPage error={error} />
  ) : (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <Title level={4}>{title}</Title>
        {poolsView ? (
          <Text type="secondary">
            Only the newest {filteredItems?.length} {title.toLowerCase()} are shown
          </Text>
        ) : null}
        <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} allowClear />
      </div>
      <Table
        columns={columns}
        dataSource={filteredItems}
        rowKey="id"
        bordered
        onChange={onChange}
        loading={isLoading}
        pagination={
          poolsView
            ? false
            : {
                ...pagination,
                total,
                showTotal: (total) => `Total ${total} items`,
              }
        }
      />
    </>
  )
}
