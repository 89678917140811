import { pick } from 'ramda'
import { Sample } from '../../services/interfaces'
import { postOrder } from '../../services/StatusDbApi'
import {
  ErrorNotification,
  getIndexSequence,
  SuccessNotification,
} from '../../services/helpers/helpers'
import dayjs from 'dayjs'
import { orderTypes } from 'services/helpers/constants'

export const serverValidator = (name, form, caseName, index) => {
  const getError = (path) => form.getFieldValue(path)

  const paths = [
    ['cases', caseName, 'samples', index, name, 'errors'],
    ['samples', index, name, 'errors'],
    ['cases', caseName, name, 'errors'],
    [name, 'errors'],
  ]

  const error = paths.map(getError).find(Boolean)

  if (Array.isArray(error) && error.length > 0) {
    return Promise.reject(error)
  }
  return Promise.resolve()
}

const getFieldValueOrNull = (field) => (field?.value !== undefined ? field.value : null)

const processSamples = (samples: Sample[] = []) =>
  (samples || [])
    .filter((sample) => sample !== null && sample !== undefined)
    .map((sample) => {
      const sampleValues: Record<string, any> = {
        processedAt: new Date().toISOString(),
      }

      Object.entries(sample).forEach(([key, value]) => {
        sampleValues.index_sequence = getIndexSequence(sample?.index, sample?.index_number)
        const dateValue = sample.collection_date?.value
        sampleValues.collection_date = dayjs(dateValue).isValid()
          ? dayjs(dateValue).format('YYYY-MM-DD')
          : dateValue
        sampleValues.family_name = getFieldValueOrNull(sample?.name)
        const extractedValue = value?.value
        if (extractedValue !== undefined) {
          sampleValues[key] = extractedValue
        }
      })

      return sampleValues
    })

export function extractCaseValues(order) {
  return {
    name: getFieldValueOrNull(order?.name),
    customer: getFieldValueOrNull(order?.customer),
    project_type: getFieldValueOrNull(order?.project_type),
    delivery_type: getFieldValueOrNull(order?.delivery_type),
    data_delivery: getFieldValueOrNull(order?.delivery_type),
    dataAnalysis: orderTypes.find((item) => item.orderType === order?.project_type?.value)
      ?.dataAnalysis,
    comment: getFieldValueOrNull(order?.comment),
    ticket: getFieldValueOrNull(order?.ticket),
    skip_reception_control: getFieldValueOrNull(order.skip_reception_control),
    cases: (order?.cases || []).map((caseItem) => ({
      internal_id: getFieldValueOrNull(caseItem?.internal_id),
      cohorts: getFieldValueOrNull(caseItem?.cohorts),
      name: getFieldValueOrNull(caseItem?.name),
      panels: getFieldValueOrNull(caseItem?.panels),
      priority: getFieldValueOrNull(caseItem?.priority),
      synopsis: getFieldValueOrNull(caseItem?.synopsis),
      samples: processSamples(caseItem?.samples),
    })),
  }
}

export function extractStandaloneValues(order) {
  return {
    customer: getFieldValueOrNull(order.customer),
    comment: getFieldValueOrNull(order.comment),
    name: getFieldValueOrNull(order.name),
    project_type: getFieldValueOrNull(order.project_type),
    dataAnalysis: orderTypes.find((item) => item.orderType === order.project_type?.value)
      ?.dataAnalysis,
    delivery_type: getFieldValueOrNull(order.delivery_type),
    data_delivery: getFieldValueOrNull(order.delivery_type),
    skip_reception_control: getFieldValueOrNull(order.skip_reception_control),
    samples: processSamples(order.samples),
  }
}

export const validateCaseOrder = async (
  formInput,
  setValidationError,
  setIsConfirmationModalOpen,
  setOrderData
) => {
  const errorMessages: string[] = []
  const hasCase = !!formInput.cases?.length
  const hasSample = formInput.cases?.every((caseItem) => !!caseItem.samples?.length)
  if (hasCase && hasSample) {
    setOrderData(extractCaseValues(formInput))
    setIsConfirmationModalOpen(true)
  } else {
    errorMessages.push('Validation error: Add at least one case to the order')
  }
  setValidationError({ hasError: errorMessages.length > 0, messages: errorMessages })
}

export const validateStandaloneOrder = (
  formInput,
  setValidationError,
  setIsConfirmationModalOpen,
  setOrderData
) => {
  const errorMessages: string[] = []
  const hasSample = !!formInput.samples?.length
  if (hasSample) {
    setOrderData(extractStandaloneValues(formInput))
    setIsConfirmationModalOpen(true)
  } else {
    errorMessages.push('Validation error: Add at least one case to the order')
  }
  setValidationError({ hasError: errorMessages.length > 0, messages: errorMessages })
}

export const submitOrder = (
  userContext,
  orderType,
  order,
  form,
  setIsConfirmationModalOpen,
  setIsSubmittingOrder
) => {
  setIsSubmittingOrder(true)
  postOrder(userContext, orderType, order)
    .then(() => {
      SuccessNotification('Order submitted!')
      form.resetFields()
      setIsConfirmationModalOpen(false)
      setIsSubmittingOrder(false)
    })
    .catch(({ response }) => {
      setIsSubmittingOrder(false)
      ErrorNotification('Could not submit the order', response.data.message)
    })
}

const wrapValues = (obj) => {
  return Object.entries(obj).reduce((acc, [key, val]) => {
    acc[key] = { value: val }
    return acc
  }, {})
}

export const buildExistingSample = (backendSample) => {
  const fields = [
    'application',
    'name',
    'mother',
    'father',
    'status',
    'internal_id',
    'sex',
    'received_at',
    'subject_id',
    'reference_genome',
  ]

  const localSample = pick(fields, backendSample)

  localSample.application = backendSample?.application?.tag
  localSample.tumour = backendSample?.is_tumour
  localSample.concentration = backendSample?.concentration

  return wrapValues(localSample)
}

export const buildExistingFamily = (backendFamily) => {
  const fields = ['name', 'cohorts', 'panels', 'internal_id', 'sex', 'priority']

  const localFamily = pick(fields, backendFamily)

  localFamily.samples = backendFamily.links.map((link) => {
    const referenceGenome = link.sample?.reference_genome
    return buildExistingSample({
      ...link.sample,
      father: link.father?.name,
      mother: link.mother?.name,
      status: link.status,
      reference_genome: referenceGenome,
    })
  })

  return {
    ...wrapValues(localFamily),
    samples: localFamily.samples,
  }
}

export const plateLength = 96
const oldSampleTresholdDate = dayjs('2016-12-01')

export const getIsPlate = (container) => container?.includes('96 well plate')
export const isPANPAZApplication = (application) =>
  application?.includes('PAN') || application?.includes('PAZ')
export const isBufferRequired = (application) =>
  application?.includes('PA') ||
  application?.includes('EX') ||
  application?.includes('WGSWPF') ||
  application?.includes('METWPF')

export const getBackgroundColor = (index) => (index % 2 === 0 ? '#f3f3f3' : '#fbfbfb')

export const findUniqueItems = (items) => [...new Map(items.map((v) => [v.name[1], v])).values()]

export const removeFileExtension = (fileName) => fileName.split('.').slice(0, -1).join('.')

export const isSampleOld = (sampleReceivedDate) =>
  dayjs(sampleReceivedDate).isBefore(oldSampleTresholdDate)

export const initIsTrustedCustomer = (customer, options) => {
  const option = options?.customers?.find((option) => option.value === customer)
  return option ? option.isTrusted : false
}
