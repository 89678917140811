import React from 'react'
import { Form, InputNumber } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const ConcentrationField = ({
  index,
  skipReceptionControl,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <Form.Item
      label="Concentration (ng/μL)"
      name={[index, 'concentration_ng_ul', 'value']}
      required={skipReceptionControl}
      rules={[
        {
          required: skipReceptionControl,
          validator: () => serverValidator('concentration_ng_ul', form, caseName, index),
        },
      ]}
    >
      <InputNumber style={{ width: 65 }} onChange={(e) => debouncedValidation(e)} />
    </Form.Item>
  )
}
