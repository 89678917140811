import React from 'react'
import { Popover, Typography } from 'antd'
import styles from './Footer.module.css'
import kiLogo from '../../assets/ki-logo.png'
import sciLogo from '../../assets/scilifelab-logo.png'
import kthLogo from '../../assets/kth-logo.png'
import sweLogo from '../../assets/swedac-logo.png'
import { PhoneOutlined, MailOutlined, GithubOutlined } from '@ant-design/icons'

const { Title, Text, Paragraph } = Typography

const Footer: React.FC = () => {
  return (
    <footer data-testid="footer" className={styles.footer}>
      <div className={styles.footerGrid}>
        <div className={styles.textElement}>
          <Title level={5} className={styles.footerTitle}>
            Contact Us
          </Title>
          <Paragraph>
            <Text>
              <PhoneOutlined /> +46 85 248 15 00
            </Text>
            <br />
            <Text>
              <MailOutlined />{' '}
              <a href="mailto:support@clinicalgenomics.se">support@clinicalgenomics.se</a>
            </Text>
            <br />
            <Text>
              <GithubOutlined />{' '}
              <a href="https://github.com/Clinical-Genomics">Clinical-Genomics</a>
            </Text>
          </Paragraph>
        </div>

        <div className={styles.textElement}>
          <Title level={5} className={styles.footerTitle}>
            Visiting Address
          </Title>
          <Paragraph>
            Clinical Genomics <br />
            Gamma 4, Tomtebodavägen 23A <br />
            171 65 Solna, Sweden
          </Paragraph>
        </div>

        <div className={styles.textElement}>
          <Title level={5} className={styles.footerTitle}>
            Mailing Address
          </Title>
          <Paragraph>
            Clinical Genomics / SciLifeLab <br />
            Attn: Clinical Genomics, Gamma 4 <br />
            PO Box 1031 <br />
            171 21 Solna, Sweden
          </Paragraph>
        </div>

        <div className={styles.logContainer}>
          <a
            href="https://www.scilifelab.se/units/clinical-genomics-stockholm/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={styles.sciLogo} src={sciLogo} alt="SciLifeLab Logo" />
          </a>
          <div className={styles.logoRow}>
            <a
              href="https://ki.se/en/research/research-infrastructure-and-environments/core-facilities-for-research/clinical-genomics-stockholm-core-facility-genomic-medicine-center-karolinska-gmck"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={styles.logo} src={kiLogo} alt="KI Logo" />
            </a>
            <a
              href="https://www.kth.se/forskning/forskningsinfrastrukturer/kth-forskningsinfrastrukturer-1.858996"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={styles.logo} src={kthLogo} alt="KTH Logo" />
            </a>
            <Popover
              content="Accredited by SWEDAC for ISO/IEC 17025 certification."
              title="ISO/IEC 17025"
            >
              <a href="https://www.swedac.se/en/" target="_blank" rel="noopener noreferrer">
                <img
                  className={`${styles.logo} ${styles.sweLogo}`}
                  src={sweLogo}
                  alt="SWEDAC Logo"
                />
              </a>
            </Popover>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
