import React from 'react'
import { Carousel, Button, Card } from 'antd'
import styles from './HomePage.module.css'
import NovaSeq_X_Flowcells_loading_short_read from '../../assets/NovaSeq X, Flowcells loading, short read.jpg'
import Bionano_Saphyr_Optical_genome_mapping from '../../assets/Bionano Saphyr Optical genome mapping.jpg'
import { Link } from 'react-router-dom'

export const HomePage = ({ user }) => {
  return (
    <div className={styles.heroContainer}>
      <Carousel autoplay autoplaySpeed={10000} arrows>
        <div>
          <img
            src={Bionano_Saphyr_Optical_genome_mapping}
            className={styles.carouselImage}
            alt="Bionano Saphyr, Optical genome mapping"
          />
        </div>
        <div>
          <img
            src={NovaSeq_X_Flowcells_loading_short_read}
            className={styles.carouselImage}
            alt="NovaSeq X Flowcells loading short read"
          />
        </div>
      </Carousel>
      <div className={styles.heroTitleBlock}>
        <h1>Clinical Genomics Stockholm | Order Portal</h1>
        {!user ? (
          <>
            <h4>Sign in to place an order or contact support for project inquiries.</h4>
            <div className={styles.buttonContainer}>
              <Link to="/contact_us">
                <Button type="primary" size="large">
                  <span>Contact Us</span>
                </Button>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className={styles.placeOrderButton}>
              <Link to={'/new-order'}>
                <Button type="primary" size="large">
                  <span>New Order</span>
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
      <Card className={styles.heroTextCard}>
        <p>
          The{' '}
          <a
            href="https://www.scilifelab.se/units/clinical-genomics-stockholm/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Clinical Genomics Stockholm
          </a>{' '}
          unit provides research infrastructure for projects using next-generation sequencing
          technologies. In collaboration with the Swedish healthcare system, it supports the
          translation of genomics tools into clinical care. The unit also strengthens public health
          microbiology for national surveillance and epidemic preparedness.
        </p>
      </Card>
    </div>
  )
}
